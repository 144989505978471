import { Popup } from 'semantic-ui-react';
import { orderBy } from 'lodash';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import { StatusBadgeArgument, filterElementStatus } from 'atoms/TableReactPrime/columnTemplates';
import DiscussionGroup from 'common/model/DiscussionGroup';
import DiscussionGroupType from 'common/model/DiscussionGroupType';
import TransactionChannel from 'common/model/TransactionChannel';
import TransactionMessage from 'common/model/TransactionMessage';

// Definition of the badge's color for the discussionGroup status
export const statuses: StatusBadgeArgument[] = [{ value: 'OPEN', color: BadgeColor.ORANGE }];

export const CommentsPopUp = (messages: TransactionMessage[], channelId: string, index: number) => {
  if (messages && messages.length !== 0) {
    messages = orderBy(messages, ['updatedAt'], ['asc']);
    const messageTextFull = messages[messages.length - 1]?.message;
    const messageTextSmall =
      messageTextFull.length > 20
        ? messageTextFull.slice(0, messageTextFull.lastIndexOf(' ', 70)) + '...'
        : messageTextFull;
    return (
      <Popup
        hoverable={true}
        key={`channel-${channelId}-${index}`}
        trigger={<div dangerouslySetInnerHTML={{ __html: messageTextSmall }} />}
        content={<div dangerouslySetInnerHTML={{ __html: messageTextFull }} />}
      />
    );
  } else {
    return '';
  }
};

export const ClausesPop = (clauseNumber: string, channelId: string, index: number) => {
  if (clauseNumber === null) {
    return '';
  }
  if (clauseNumber.length >= 35) {
    const messageTextFull = clauseNumber;
    const messageTextSmall =
      messageTextFull.length > 35
        ? messageTextFull.slice(0, messageTextFull.lastIndexOf(' ', 70)) + '...'
        : messageTextFull;
    return (
      <Popup
        hoverable={true}
        key={`clause-number-${channelId}-${index}`}
        trigger={<p>{messageTextSmall}</p>}
        content={messageTextFull}
      />
    );
  } else {
    return <p>{clauseNumber}</p>;
  }
};

export class DiscussionGroupClone {
  id: string;
  transactionId: string;
  documentTypeId: string;
  provisionName: string;
  type: string;
  index: number;
  channels: TransactionChannel[];

  constructor(discussionGroup: DiscussionGroup) {
    this.id = discussionGroup.id;
    this.transactionId = discussionGroup.transaction.id;
    this.documentTypeId = discussionGroup.documentType.id;
    this.provisionName = discussionGroup.provisionName;
    this.type = discussionGroup.type;
    this.index = discussionGroup.index;
    this.channels = discussionGroup.transactionChannels;
  }
}

/*
 * Definition of the column (names, format...)
 */
// TODO
// Filter function to be fixed
export const discussionColumnConfig = (): CustomColumnProps[] => {
  return [
    {
      field: 'subjectMatter',
      header: 'Subject Matter',
      filterPlaceholder: 'Search by Subject Matter',
      filter: true,
      filterField: 'subjectMatter',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.type === DiscussionGroupType.Provision ? row.provisionName : DiscussionGroupType.General;
      },
    },
    {
      field: 'discussions',
      header: 'Discussions',
      filterPlaceholder: 'Search by Discussions',
      filter: true,
      filterField: 'discussions',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.channels.slice(0, 3).map((channel: TransactionChannel) => <p>{channel.title}</p>);
      },
    },
    {
      field: 'clauses',
      header: 'Clauses',
      filterPlaceholder: 'Search by Clauses',
      filter: true,
      filterField: 'clauses',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.channels
          .slice(0, 3)
          .map((channel: TransactionChannel, index: number) => ClausesPop(channel.clauseNumber, channel.id, index));
      },
    },
    {
      field: 'tenantComments',
      header: 'Tenant Comments/Edits',
      filterPlaceholder: 'Search by Tenant Comments',
      filter: true,
      filterField: 'tenantComments',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.channels
          .slice(0, 3)
          .map((channel: TransactionChannel, index: number) => CommentsPopUp(channel.messages, channel.id, index));
      },
    },
    // TO BE UPDATED
    {
      field: 'landlordResponse',
      header: 'Landlord Response',
      filterPlaceholder: 'Search by Landlord Response',
      filter: true,
      filterField: 'landlordResponse',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return '';
      },
    },
    {
      field: 'subjectStatus',
      header: 'Status',
      filterPlaceholder: 'Search by Status',
      filter: true,
      filterField: 'status',
      sortable: true,
      body: (row: DiscussionGroupClone) => '',
      filterElement: options => filterElementStatus(options, statuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
  ];
};
