import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import SelectField from 'atoms/FormField/Select';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { FilterItemChildProps } from '.';
import {
  ComparablesTableFilterDropdownOptions,
  FilterMode,
  FilterOnchangeKeys,
  RowAction,
  RowProps,
} from '../../RowConfig/RowProps';
import { filterWhile } from './StringFilter';

export const LIST_FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Contains', value: FilterMode.CONTAINS },
  { key: 2, text: 'Does not contain', value: FilterMode.DOES_NOT_CONTAIN },
];

const compare = (value: string, filterValue: string[], filterMode: FilterMode) => {
  const trimmedValue = value.toLowerCase().trim();
  const trimmedFilterValue = filterValue.map(value => value.toLowerCase().trim());

  switch (filterMode) {
    case FilterMode.CONTAINS:
      return trimmedFilterValue.includes(trimmedValue);

    case FilterMode.DOES_NOT_CONTAIN:
      return !trimmedFilterValue.includes(trimmedValue);
  }

  return true;
};

export function applyListFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyListFilter: field key is missing');

  let value: string | string[] = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  if (typeof value === 'string') {
    value = value.toLowerCase().trim();
  }

  return filterWhile(value, rowAction, compare);
}

const ListFilter = ({ options, onChange, filterItem }: FilterItemChildProps): JSX.Element => {
  console.log('options => ', options);
  return (
    <>
      <SelectField
        placeholder="Select..."
        fieldKey={FilterOnchangeKeys.filterMode}
        value={filterItem.filterMode}
        options={LIST_FILTER_OPTIONS}
        onChange={onChange}
        className="m-b-s"
      />
      <MultiSelect
        appendTo="self"
        value={filterItem.filterValue}
        options={options}
        itemTemplate={(option: any) => option.text}
        onChange={(e: MultiSelectChangeEvent) => onChange(FilterOnchangeKeys.filterValue, e.value)}
        placeholder="Value"
        className="p-column-filter w-100"
        dataKey="value"
        optionLabel="text"
      />
    </>
  );
};

export default ListFilter;
