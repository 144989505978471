import { CDuration, CValuationReport } from 'common/_classes/valuationReport2';
import moment from 'moment';
import Badge, { BadgeColor } from 'atoms/Badge';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';
import {
  BUILDING_GRADE_OPTIONS,
  CHARACTERISTICS_OPTIONS,
  HANDOVER_CONDITION_OPTIONS,
  LEASE_TYPE_OPTIONS,
  PERMITTED_USE_OPTIONS,
  SPECIFIC_USE_OPTIONS,
} from 'utils/UI';
import { addSpaceOrComma } from 'utils/utils-number';
import { replaceUnderscoreWithSpace } from 'utils/utils-string';
import {
  assignmentRightsBody,
  buildingGradeBody,
  expansionRightsBody,
  intialRentAgreementDateBody,
  originalBindingContractDateBody,
  partAndWholeFloorCountsBody,
  premisesBody,
  refusalRightsBody,
  renewalRightsBody,
  rentAgreementDetailsBody,
  rentReviewDetailsBody,
  saleAndRedevelopmentBody,
  shareRightsBody,
  subletRightsBody,
  surrenderRightsBody,
  unitViewBody,
} from './RowBody';
import { vacancyRateAtRentAgreementBody } from './RowBody/vacancyRateAtRentAgreementBody';
import { DataType, FilterMatchType, FilterMode, RowAction, RowLabel, RowProps } from './RowProps';

/**
 * List of row items
 * contains basic details, filters status, sorting and templates
 */
export const RowConfigs = (unfilteredReport: CValuationReport[]): RowProps[] =>
  [
    // Fundamentals
    {
      label: RowLabel.BUILDING,
      field: 'data,buildingFeatures,name',
      columnClass: 'bg-orange-very-light-grayish',
      body: ({ data: { buildingFeatures } }: CValuationReport) => buildingFeatures.name,
      dataType: DataType.LIST,
      options: (() => {
        const seenNames = new Set();
        return unfilteredReport
          .map((report, index) => ({
            key: index,
            text: report.data.buildingFeatures.name,
            value: report.data.buildingFeatures.name,
          }))
          .filter(option => {
            if (seenNames.has(option.value)) {
              return false; // Exclude duplicates
            }
            seenNames.add(option.value);
            return true; // Include unique names
          });
      })(),
      defaultFilter: (report: CValuationReport, rowConfig: RowProps) => {
        const buildingName = report.data.buildingFeatures.name;

        return {
          ...rowConfig,
          filterMatchType: FilterMatchType.ALL,
          filterConditions: [
            {
              filterValue: [buildingName],
              filterMode: FilterMode.CONTAINS,
            },
          ],
          filterActive: true,
        };
      },
    },
    {
      label: RowLabel.PREMISES,
      field: 'data,premises,floors',
      columnClass: 'bg-orange-very-light-grayish',
      body: premisesBody,
      dataType: DataType.PREMISE,
      hideSorting: true,
      options: (() => {
        const seenNames = new Set();
        const floorOptions: any[] = [];

        unfilteredReport.forEach(report => {
          report.data.premises.floors.forEach((floor, index) => {
            if (!seenNames.has(floor.name)) {
              seenNames.add(floor.name);
              floorOptions.push({
                key: `${report.id}-${index}`,
                text: floor.name,
                value: floor.name,
              });
            }
          });
        });

        return floorOptions;
      })(),
    },
    {
      label: RowLabel.TENANT,
      field: 'data,tenant',
      columnClass: 'bg-orange-very-light-grayish',
      body: ({ data: { tenant } }: CValuationReport) => tenant,
      dataType: DataType.STRING,
    },
    {
      label: RowLabel.PREMISES_SIZE,
      field: 'data,premiseSize',
      body: ({ data: { premiseSize } }: CValuationReport) => {
        return premiseSize ? premiseSize.toNumber() : '';
      },
      columnClass: 'bg-orange-very-light-grayish',
      dataType: DataType.DECIMAL,
    },
    {
      label: RowLabel.FACE_RENT_PSF_PCM,
      field: 'data,faceRentPsfPcm',
      body: ({ data: { faceRentPsfPcm } }: CValuationReport) => faceRentPsfPcm?.toNumber() || null,
      columnClass: 'bg-orange-very-light-grayish',
      dataType: DataType.DECIMAL,
    },
    {
      label: RowLabel.EFFECTIVE_RENT_PSF_PCM,
      field: 'data,effectiveRentPsfPcm',
      body: ({ data: { effectiveRentPsfPcm } }: CValuationReport) => effectiveRentPsfPcm?.toNumber() || null,
      columnClass: 'bg-orange-very-light-grayish',
      dataType: DataType.DECIMAL,
    },
    // TERM
    {
      label: RowLabel.TERM_SECTION,
      isHeading: true,
    },
    {
      label: RowLabel.TERM_START_DATE,
      field: 'data,term,startDate',
      body: ({ data: { term } }: CValuationReport) => moment(term.startDate).format('DD/MM/YYYY'),
      dataType: DataType.DATE,
    },
    {
      label: RowLabel.TERM_END_DATE,
      field: 'data,term,endDate',
      body: ({ data: { term } }: CValuationReport) => moment(term.endDate).format('DD/MM/YYYY'),
      dataType: DataType.DATE,
    },
    {
      label: RowLabel.TERM_LENGTH,
      field: 'data,term,duration',
      body: ({ data: { term } }: CValuationReport) => CDuration.toString(term.duration),
      dataType: DataType.DURATION,
    },
    // RENT FREE & EARLY OCCUPATION
    {
      label: RowLabel.RENT_FREE_AND_EARLY_OCCUPATION_SECTION,
      isHeading: true,
    },
    {
      label: RowLabel.RENT_FREE_PERIOD,
      field: 'data,rentFreeAndEarlyOccupation,rentFreePeriod',
      body: ({ data: { rentFreeAndEarlyOccupation } }: CValuationReport) => rentFreeAndEarlyOccupation.rentFreePeriod,
      dataType: DataType.NUMBER,
    },
    {
      label: RowLabel.INCENTIVE_RENT_FREE,
      field: 'data,rentFreeAndEarlyOccupation,incentiveRentFree',
      body: ({
        data: {
          rentFreeAndEarlyOccupation: { incentiveRentFree },
        },
      }: CValuationReport) => (incentiveRentFree ? CDuration.toString(incentiveRentFree) : null),
      dataType: DataType.DURATION,
    },
    {
      label: RowLabel.EARLY_OCCUPATION_LICENSE,
      field: 'data,rentFreeAndEarlyOccupation,incentiveRentFree',
      body: ({
        data: {
          rentFreeAndEarlyOccupation: { earlyOccupationLicense },
        },
      }: CValuationReport) => (earlyOccupationLicense ? CDuration.toString(earlyOccupationLicense) : null),
      dataType: DataType.DURATION,
    },
    // RENT & RENT REVIEW
    {
      label: RowLabel.RENT_AND_RENT_REVIEW_SECTION,
      isHeading: true,
    },
    {
      label: RowLabel.DATE_INITIAL_RENT_FIRST_AGREED,
      field: 'data,rentReview,0,rentAgreementDate',
      body: intialRentAgreementDateBody,
      dataType: DataType.DATE,
    },
    {
      label: RowLabel.DATE_BINDING_CONTRACT_WAS_ORIGINALLY_AGREED,
      field: 'data,rentReview,0,rentDeterminationDate',
      body: originalBindingContractDateBody,
      dataType: DataType.DATE,
    },
    {
      label: RowLabel.VACANCY_RATE_AT_RENT_AGREEMENT,
      field: 'data,rentReview,0,vacancyRate',
      body: vacancyRateAtRentAgreementBody,
      dataType: DataType.DECIMAL,
    },
    {
      label: RowLabel.RENT_REVIEW_DETAILS,
      field: 'data,rentReview',
      body: rentReviewDetailsBody,
      dataType: DataType.STRING,
      hideSorting: true,
      hideFilter: true,
    },
    {
      label: RowLabel.RENTS_AGREED_OR_DETERMINED,
      field: 'data,rentReview',
      body: rentAgreementDetailsBody,
      dataType: DataType.DURATION,
      hideSorting: true,
      hideFilter: true,
    },
    // SECURITY
    {
      label: RowLabel.SECURITY_SECTION,
      isHeading: true,
    },
    {
      label: RowLabel.SECURITY_MONTHS,
      field: 'data,security,months',
      body: ({ data: { security } }: CValuationReport) => security.months,
      dataType: DataType.NUMBER,
    },
    {
      label: RowLabel.SECURITY_AMOUNT,
      field: 'data,security,amount',
      body: ({
        data: {
          security: { amount },
        },
      }: CValuationReport) => (amount !== null ? addSpaceOrComma(amount.toString(), false) : amount),
      dataType: DataType.DECIMAL,
    },
    // SPECIAL RIGHTS
    {
      label: RowLabel.SPECIAL_RIGHTS_SECTION,
      isHeading: true,
    },
    {
      label: RowLabel.OPTION_TO_RENEW,
      field: 'data,specialRights,renewFlag',
      body: renewalRightsBody,
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.RIGHT_TO_SURRENDER,
      field: 'data,specialRights,surrenderFlag',
      body: surrenderRightsBody,
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.EXPANSION_RIGHT,
      field: 'data,specialRights,expansionFlag',
      body: expansionRightsBody,
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.RIGHT_TO_SUBLET,
      field: 'data,specialRights,subletFlag',
      body: subletRightsBody,
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.RIGHT_TO_SHARE,
      field: 'data,specialRights,shareFlag',
      body: shareRightsBody,
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.RIGHT_TO_ASSIGN,
      field: 'data,specialRights,assignmentFlag',
      body: assignmentRightsBody,
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.RIGHT_TO_REFUSE,
      field: 'data,specialRights,refusalFlag',
      body: refusalRightsBody,
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.SALE_AND_REDEVELOPMENT,
      field: 'data,specialRights,saleAndRedevelopmentFlag',
      body: saleAndRedevelopmentBody,
      dataType: DataType.BOOLEAN,
    },
    // BUILDING CHARACTERISTICS
    {
      label: RowLabel.BUILDING_CHARACTERISTICS_SECTION,
      isHeading: true,
    },
    {
      label: RowLabel.BUILDING_TYPE,
      field: 'data,buildingFeatures,useType',
      body: buildingGradeBody,
      dataType: DataType.LIST,
      options: BUILDING_GRADE_OPTIONS,
      hideSorting: true,
    },
    {
      label: RowLabel.COVERED_ACCESS_TO_MTR,
      field: 'data,buildingFeatures,coveredAccessToMtr',
      body: ({ data: { buildingFeatures } }: CValuationReport) =>
        filterBooleanElementBody(buildingFeatures.coveredAccessToMtr),
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.CENTRAL_AC,
      field: 'data,buildingFeatures,centralAc',
      body: ({ data: { buildingFeatures } }: CValuationReport) => filterBooleanElementBody(buildingFeatures.centralAc),
      dataType: DataType.BOOLEAN,
    },
    {
      label: RowLabel.CURTAIN_WALL,
      field: 'data,buildingFeatures,curtainWall',
      body: ({ data: { buildingFeatures } }: CValuationReport) =>
        filterBooleanElementBody(buildingFeatures.curtainWall),
      dataType: DataType.BOOLEAN,
    },
    // PREMISES CHARACTERISTICS
    {
      label: RowLabel.PREMISES_CHARACTERISTICS_SECTION,
      isHeading: true,
    },
    {
      label: RowLabel.PERMITTED_USE,
      field: 'data,premisesCharacteristics,permittedUse',
      body: ({ data: { premisesCharacteristics } }: CValuationReport) =>
        premisesCharacteristics.permittedUse ? (
          <Badge badgeColor={BadgeColor.GRAY}>{replaceUnderscoreWithSpace(premisesCharacteristics.permittedUse)}</Badge>
        ) : (
          ''
        ),
      dataType: DataType.LIST,
      options: PERMITTED_USE_OPTIONS,
      hideSorting: true,
      defaultFilter: (report: CValuationReport, rowConfig: RowProps) => {
        const permittedUse = report.data.premisesCharacteristics.permittedUse;

        return {
          ...rowConfig,
          filterMatchType: FilterMatchType.ALL,
          filterConditions: [
            {
              filterValue: [permittedUse],
              filterMode: FilterMode.CONTAINS,
            },
          ],
          filterActive: true,
        };
      },
    },
    {
      label: RowLabel.SPECIFIC_USE,
      field: 'data,premisesCharacteristics,specificUse',
      body: ({ data: { premisesCharacteristics } }: CValuationReport) =>
        premisesCharacteristics.specificUse ? (
          <Badge badgeColor={BadgeColor.GRAY}>{replaceUnderscoreWithSpace(premisesCharacteristics.specificUse)}</Badge>
        ) : (
          ''
        ),
      dataType: DataType.LIST,
      options: SPECIFIC_USE_OPTIONS,
      hideSorting: true,
    },
    {
      label: RowLabel.HANDOVER_CONDITION,
      field: 'data,premisesCharacteristics,handoverCondition',
      body: ({ data: { premisesCharacteristics } }: CValuationReport) =>
        premisesCharacteristics.handoverCondition ? (
          <Badge badgeColor={BadgeColor.GRAY}>
            {replaceUnderscoreWithSpace(premisesCharacteristics.handoverCondition)}
          </Badge>
        ) : (
          ''
        ),
      dataType: DataType.LIST,
      options: HANDOVER_CONDITION_OPTIONS,
      hideSorting: true,
    },
    {
      label: RowLabel.WHOLE_OR_PART_FLOORS,
      field: 'wholeFloorsCount,partFloorsCount',
      body: partAndWholeFloorCountsBody,
      dataType: DataType.NUMBER,
      hideSorting: true,
      hideFilter: true,
    },
    {
      label: RowLabel.VIEW,
      field: 'data,buildingFeatures,useType',
      body: unitViewBody,
      dataType: DataType.LIST,
      options: CHARACTERISTICS_OPTIONS,
      hideSorting: true,
      hideFilter: true,
    },
    {
      label: RowLabel.EFFICIENCY_PERCENTAGE,
      field: 'data,premisesCharacteristics,efficiency',
      body: ({ data: { premisesCharacteristics } }: CValuationReport) => premisesCharacteristics.efficiency.toNumber(),
      dataType: DataType.DECIMAL,
    },
    // OTHER
    {
      label: RowLabel.OTHER_SECTION,
      isHeading: true,
    },
    {
      label: RowLabel.SPECIAL_REMARKS,
      field: 'data,others,specialRemarks',
      body: ({ data: { others } }: CValuationReport) => others.specialRemarks,
      dataType: DataType.STRING,
    },
    {
      label: RowLabel.TENANT_INDUSTRY,
      field: 'data,others,tenantIndustry',
      body: ({ data: { others } }: CValuationReport) => others.tenantIndustry,
      dataType: DataType.STRING,
    },
    {
      label: RowLabel.NATURE_OF_LEASE,
      field: 'data,others,natureOfLease',
      body: ({ data: { others } }: CValuationReport) =>
        others.natureOfLease ? (
          <Badge badgeColor={BadgeColor.GRAY}>{replaceUnderscoreWithSpace(others.natureOfLease)}</Badge>
        ) : (
          ''
        ),
      dataType: DataType.LIST,
      options: LEASE_TYPE_OPTIONS,
      hideSorting: true,
    },
    {
      label: RowLabel.AGENT_COMPANY,
      field: 'data,others,agentCompany',
      body: ({ data: { others } }: CValuationReport) => others.agentCompany,
      dataType: DataType.STRING,
    },
    {
      label: RowLabel.AGENT_NAME,
      field: 'data,others,agentName',
      body: ({ data: { others } }: CValuationReport) => others.agentName,
      dataType: DataType.STRING,
    },
  ].map((row, index) => {
    return { ...row, id: index };
  });
