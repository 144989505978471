import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import FullScreen from 'layouts/FullScreen';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import DropdownMenu, { DropdownItem } from 'atoms/DropdownMenu';
import { updateDocTypeId } from 'store/miscellaneous/miscellaneousSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import { getPDFDocument, getPdfHtml } from 'common/api/miscellaneous';
import { PolicyProps } from 'common/api/policies';
import { TransactionProps, getTenantTransaction } from 'common/api/transactions';
import { Icons } from 'utils/utils-icons';
import ConfigureSendToLandlordModal from './ConfigureSendToLandlordModal';
import TenantPreviewEditorMain from './TenantPreviewEditorMain';
import TenantPreviewSideMenu from './TenantPreviewSideMenu';

const TransactionPreview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const context: ExecuteContext = ExecuteContext.Transaction;

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const { activePolicy } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction } = useAppSelector((state: RootState) => state.transactionDetail);
  const { selectedTenant } = useAppSelector((state: RootState) => state.transactionsListing);
  const { documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);

  // TODO output should be a string...
  const getFirstDoc = (documentTypeIds: string[]) => {
    for (let i = 0; i < documentTypesList.length; i++) {
      if (documentTypeIds.includes(documentTypesList[i].id)) {
        return documentTypesList[i].id;
      }
    }
  };

  const contextId: string | undefined = activeTransaction.id;

  useEffect(() => {
    if (selectedTenant) {
      dispatch(getTenantTransaction({ id }));
    } else {
      navigate('/transactions/dashboard');
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeTransaction.id) {
      const activeContext: PolicyProps | TransactionProps = activeTransaction;
      const docId: string | null | undefined =
        activeContext.documentTypeIds.length !== 0 ? getFirstDoc(activeContext.documentTypeIds) : null;
      dispatch(updateDocTypeId(docId));
    }
  }, [activeTransaction.id]);

  const transactionPage: boolean = true;

  const contextName: string | null = transactionPage ? activeTransaction?.identifier : activePolicy?.name;

  const previewDropdownItems: DropdownItem[] = [
    {
      key: '1',
      label: 'Preview HTML',
      dataTest: 'preview-html-button',
      icon: Icons.Html,
      onClick: () =>
        dispatch(
          getPdfHtml({
            context: context,
            contextId,
            documentTypeId: null,
          }),
        ),
    },
    {
      key: '2',
      label: 'Generate Pdf Template',
      dataTest: 'generate-pdf-button',
      icon: Icons.Pdf,
      onClick: () =>
        dispatch(
          getPDFDocument({
            context: context,
            contextId,
            contextName,
          }),
        ),
    },
  ];

  const editorAndSidePanel = (
    <div className="editor-container p-sm">
      <div className="d-flex editor-top-left">
        <Button
          className="btn grey-outline"
          onClick={() => setIsFullScreen(!isFullScreen)}
          data-test={isFullScreen == true ? 'go-back-button' : 'full-screen-button'}
        >
          {isFullScreen ? 'GO BACK' : 'FULLSCREEN'}
        </Button>
        <DropdownMenu
          className="preview-menu-dropdown ui button btn grey-outline m-t-none"
          data-test="preview-dropdown"
          dropdownText="Preview"
          dataTest="preview-dropdown-button"
          dropdownItems={previewDropdownItems}
        />
      </div>

      <TenantPreviewEditorMain context={context} />
      <TenantPreviewSideMenu context={context} />
    </div>
  );

  return isFullScreen ? <FullScreen>{editorAndSidePanel}</FullScreen> : editorAndSidePanel;
};

const TransactionTenantViewer = () => {
  const isLoading = false;
  const getPageHeaderProps = (): PageHeaderProps => {
    const { activeTransaction } = useAppSelector((state: RootState) => state.transactionDetail);
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Transactions',
        url: '/transactions/dashboard',
      },
      {
        title: activeTransaction.identifier || '',
      },
    ];

    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <ConfigureSendToLandlordModal />
      <TransactionPreview />
    </PageLayout>
  );
};

export default TransactionTenantViewer;
